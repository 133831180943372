var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Basic Toast"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeBasic) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Push notifications to your visitors with a ")]), _c('code', [_vm._v("<b-toast>")]), _c('span', [_vm._v(" and ")]), _c('code', [_vm._v("<b-toaster>,")]), _c('span', [_vm._v(" lightweigt components which are easily customizable for generating alert messages.")])]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$bvToast.show('example-toast');
      }
    }
  }, [_vm._v(" Show toast ")]), _c('b-toast', {
    attrs: {
      "id": "example-toast"
    },
    scopedSlots: _vm._u([{
      key: "toast-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex flex-grow-1 align-items-center mr-1"
        }, [_c('b-img', {
          staticClass: "mr-1",
          attrs: {
            "src": require('@/assets/images/logo/logo.png'),
            "height": "18",
            "width": "25",
            "alt": "Toast image"
          }
        }), _c('strong', {
          staticClass: "mr-auto"
        }, [_vm._v("Vue Admin")]), _c('small', {
          staticClass: "text-muted"
        }, [_vm._v("11 mins ago")])], 1)];
      },
      proxy: true
    }])
  }, [_c('span', [_vm._v("Hello, world! This is a toast message. Hope you're doing well.. :)")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }