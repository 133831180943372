var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('toasts-basic'), _c('toasts-translucent'), _c('toasts-variants'), _c('toasts-delay'), _c('toasts-target'), _c('toasts-links'), _c('toasts-advance')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }