var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "no-body": "",
      "title": "Translucent"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeTranslucent) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-body', [_c('b-card-text', [_c('span', [_vm._v("we are using the ")]), _c('code', [_vm._v("static")]), _c('span', [_vm._v(" prop in the above example to render the toast in-place in the document, rather than transporting it to a ")]), _c('code', [_vm._v("<b-toaster>")]), _c('span', [_vm._v(" target container")])])], 1), _c('div', {
    staticClass: "translucent-wrap bg-primary rounded-bottom p-1"
  }, [_c('b-toast', {
    attrs: {
      "id": "example-toast3",
      "static": "",
      "no-auto-hide": ""
    },
    scopedSlots: _vm._u([{
      key: "toast-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex flex-grow-1 align-items-center mr-1"
        }, [_c('b-img', {
          staticClass: "mr-1",
          attrs: {
            "src": require('@/assets/images/logo/logo.png'),
            "height": "18",
            "width": "25",
            "alt": "Toast image"
          }
        }), _c('strong', {
          staticClass: "mr-auto"
        }, [_vm._v("Vue Admin")]), _c('small', {
          staticClass: "text-muted"
        }, [_vm._v("11 mins ago")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('span', [_vm._v("Hello, world! This is a toast message.")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }