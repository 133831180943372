var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Variants"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeVariant) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" BootstrapVue toasts provide custom CSS to define color variants. Variants follow the standard Bootstrap v4 variant names. If you have custom SCSS defined Bootstrap color theme variants, the toast custom SCSS will automatically create toast variants for you (refer to the Theming reference section). ")]), _c('div', {
    staticClass: "demo-inline-spacing"
  }, [_c('b-button', {
    attrs: {
      "variant": "gradient-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.makeToast();
      }
    }
  }, [_vm._v(" Default ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.makeToast('primary');
      }
    }
  }, [_vm._v(" Primary ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.makeToast('secondary');
      }
    }
  }, [_vm._v(" Secondary ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 159, 67, 0.15)',
      expression: "'rgba(255, 159, 67, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-warning"
    },
    on: {
      "click": function click($event) {
        return _vm.makeToast('warning');
      }
    }
  }, [_vm._v(" Warning ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 159, 67, 0.15)',
      expression: "'rgba(255, 159, 67, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-danger"
    },
    on: {
      "click": function click($event) {
        return _vm.makeToast('danger');
      }
    }
  }, [_vm._v(" Danger ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(40, 199, 111, 0.15)',
      expression: "'rgba(40, 199, 111, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-success"
    },
    on: {
      "click": function click($event) {
        return _vm.makeToast('success');
      }
    }
  }, [_vm._v(" Success ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(0, 207, 232, 0.15)',
      expression: "'rgba(0, 207, 232, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-info"
    },
    on: {
      "click": function click($event) {
        return _vm.makeToast('info');
      }
    }
  }, [_vm._v(" Info ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }